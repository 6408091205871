@import "../colors";

@font-face {
    font-family: 'neuropolitical';
    src: url('../neuropolitical/neuropolitical\ rg.otf') format('truetype');
}

.navbar {
    background-color: $backgroundColor;
    border-radius: 15px;
    padding: 10px 20px;
    font-weight: bold;
    color: $fontHeadingColor;
    
    .left {
        padding: 10px;
        font-size: 24px;
        display: flex;
        gap: 10px;
        
        .vertical {
            height: 50px;
            width: 22px;
            background-color: white;
            animation: vertical 0.4s;
        }
        @keyframes vertical {
            from {
                transform: translateY(100vh);
            }
            to {
                transform: translateY(0);
            }
        }

        .diagonal {
            height: 50px;
            width: 22px;
            font-size: 24px;
            background-color: $fontHeadingColor;
            position: relative;
            bottom: -2.5px;
            transform: rotate(-35deg);
            animation: diagonal 0.4s;
        }
        @keyframes diagonal {
            from {
                transform: translateX(100vw);
            }
            to {
                transform: translateX(0);
            }
        }
    }
    
    .right {
        display: flex;
        gap: 5px;
        
        div {
            padding: 15px;
            
            a {
                text-decoration: none;
                color: $fontHeadingColor;
                font-size: 1rem;
            }
        }
        div:hover {
            cursor: pointer;
            
            a {
                color: $fontTextColor;
            }
        }
    }
}

@media (max-width: 1000px) {
    .right {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 50px;
        z-index: -1;
        background-color: $backgroundColor;
        border-radius: 10px;
        height: 40vh;
        width: 25vw;
        display: none;
        flex-direction: column;
        padding: 10px;
        justify-content: space-evenly;

    }
}
@media (max-width: 600px){
    .right {
        width: 40vw;
    }
}