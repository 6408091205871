@import "../colors";

@font-face {
    font-family: 'neuropolitical';
    src: url('../neuropolitical/neuropolitical\ rg.otf') format('truetype');
}

.experienceSection {
    background-color: $backgroundColor;
    padding: 3rem;
    font-family: neuropolitical;

    h3 {
        color: $fontHeadingColor;
    }

    .experiences {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        justify-content: space-around;
        
        .exp {
            background-color: #FFFFFF;
            padding: 20px;
            border-radius: 10px;
            // color: $fontTextColor; 
            color: $backgroundColor; 
            transition: transform 0.3s ease,0.3s ease;
        }
        .exp:hover{
            transform: translateY(-5px);
        }
    }
}