@import "../colors";

@font-face {
    font-family: 'neuropolitical';
    src: url('../neuropolitical/neuropolitical\ rg.otf') format('truetype');
}

.homeSection {
    font-family: neuropolitical;
    height: 100vh;
    
    .homeArea {
        background-color: $backgroundColor;
        height: 80vh;
        border-radius: 15px;
        position: relative;
        top: 10vh;
    
        .top {
            height: 10vh;
        }

        .bottom {
            height: 70vh;
            display: flex;
            gap: 5vw;
            padding: 5vh 10vw 15vh 10vw;
            align-items: flex-end;
            
            .bottomLeft {
                padding: 10px;
                display: flex;
                flex-direction: column;
                height: 20vh;
                width: 5vw;
                justify-content: space-between;
                border-right: 1px solid $fontHeadingColor;
                animation: slideFromLeft 1s ease-in-out forwards; 
                
                @media (max-width: 800px) {
                    display: block;
                    height: 5vh;
                    width: 60%;
                    text-align: start;
                    border: none;
                    border-top: 1px solid $fontHeadingColor;
                    
                    a {
                        padding: 10px;
                    }
                }
            }
            .bottomRight {
                display: flex;
                width: 100%;
                height: 100%;
                
                .text {
                    padding: 15px;
                    border-radius: 10px;
                    height: 30vh;
                    width: 100%;
                    align-items: center;

                    @media (max-width: 1000px) {
                        display: flex;
                        flex-direction: column;
                    }
                    
                    h3 {
                        margin-bottom: -15px;
                        color: $fontTextColor;
                    }
                    
                    span {
                        font-size: 8rem;
                        font-weight: bold;
                        line-height: 1;
                        color: transparent;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-image: url('../../photos/code_image.png');
                        background-size: cover;
                    }
                }
            }
            
            @media (max-width: 700px) {
                flex-direction: column-reverse;
                align-items: start;
                padding: 5vh 10vw;
            }
        }

        @keyframes slideFromLeft {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(80%);
            }
        }
    }
} 